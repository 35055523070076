import { VFC } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SuccessPage from './pages/SuccessPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import Waitlist from './pages/Waitlist';

export const ROUTE_LINKS = {
  Home: '/',
  Success: '/success',
  PrivacyPolicy: '/privacypolicy',
  Wallet: '/wallet',
  Docs: '/docs',
  NotFound: '*',
};

const Routes: VFC = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={ROUTE_LINKS.Home} component={HomePage} />
      <Route exact path={ROUTE_LINKS.Success} component={SuccessPage} />
      <Route
        exact
        path={ROUTE_LINKS.PrivacyPolicy}
        component={PrivacyPolicyPage}
      />
      <Route
        path={ROUTE_LINKS.Docs}
        component={() => {
          window.location.href = 'https://mesonmix.net/docs';

          return null;
        }}
      />
      <Route exact path={ROUTE_LINKS.Wallet} component={Waitlist} />
      <Redirect from={ROUTE_LINKS.NotFound} to={ROUTE_LINKS.Home} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
