import React from 'react';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WaitlistJoin from 'components/subpages/WaitlistJoin';
import WaitlistDone from 'components/subpages/WaitlistDone';
import { useSelector } from 'react-redux';
import { RootState } from 'features/reducers';
import Navbar from 'components/modules/Navbar';
import Footer from '../modules/Footer';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    '& > footer > div > div > small': {
      color: '#ffff',
    },
    '& > footer > div > div > small > a': {
      color: palette.primary.light,
    },
  },
  backDrop: {
    backdropFilter: 'blur(0.5rem)',
    backgroundColor: 'rgba(31, 91, 146, 0.8)',
  },
  container: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    [breakpoints.down('sm')]: {
      width: '90%',
      margin: '0 0 0 0',
    },
  },
  logo: {
    object: 'fit',
    width: '30px',
    marginRight: '6px',
    [breakpoints.down('sm')]: {
      width: '24px',
    },
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginBottom: '10px',
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  service: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Wallet: React.FC = () => {
  const classes = useStyles();
  const joinedWaitlist = useSelector<RootState>(
    (state) => state.waitlist.joinedWaitlist,
  );

  return (
    <div className={classes.wrapper}>
      <Navbar />
      <Dialog
        open
        maxWidth="md"
        classes={{ paper: classes.container }}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        style={{ position: 'initial' }}
      >
        {joinedWaitlist ? <WaitlistDone /> : <WaitlistJoin />}
        <div className={classes.socialMedia}>
          <div className={classes.service}>
            <a
              href="https://twitter.com/MesonMixnet"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./assets/twitter-logo.png"
                alt="twitter-logo"
                className={classes.logo}
              />
            </a>
            <span>
              Follow{' '}
              <a
                href="https://twitter.com/MesonMixnet"
                target="_blank"
                rel="noopener noreferrer"
              >
                @MesonMixnet
              </a>
            </span>
          </div>
          <div className={classes.service}>
            <a
              href="https://app.element.io/#/room/#meson-community:matrix.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./assets/element-logo.svg"
                alt="element-logo"
                className={classes.logo}
              />
            </a>
            <span>
              Join{' '}
              <a
                href="https://app.element.io/#/room/#meson-community:matrix.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                our community
              </a>
            </span>
          </div>
        </div>
      </Dialog>
      <Footer />
      <noscript>Please enable JavaScript</noscript>
    </div>
  );
};
export default Wallet;
