import React from 'react';
import { makeStyles } from '@material-ui/core';
import Navbar from 'components/modules/Navbar';
// import ComingSoon from '../subpages/ComingSoon';
import MainContents from 'components/subpages/MainContents';
import Footer from '../modules/Footer';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0',
    minHeight: '100vh',
    minWidth: '100vw',
    // For new page
    background: 'linear-gradient(#FBF8F8, #38C6F4)',
    padding: '0 10rem',
    overflowX: 'hidden',
    width: '100%',
    [breakpoints.down('md')]: {
      padding: '0 3rem',
    },
    [breakpoints.down('sm')]: {
      padding: '0 2rem',
    },
    [breakpoints.down('xs')]: {
      padding: '0 1.5rem',
    },

    '& > footer > div > div > small': {
      color: '#ffff',
    },
    '& > footer > div > div > small > a': {
      color: palette.primary.light,
    },
  },
}));

const HomePage: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Navbar />
        {/* <ComingSoon /> */}
        <MainContents />
        <Footer />
      </div>
    </>
  );
};
export default HomePage;
