/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable camelcase */
import React from 'react';
import { DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { RootState } from 'features/reducers';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '0',
    paddingBottom: '10px',
    marginRight: '50px',
    marginLeft: '50px',
    [breakpoints.down('sm')]: {
      marginRight: '20px',
      marginLeft: '20px',
    },
    [breakpoints.down('xs')]: {
      marginRight: '4px',
      marginLeft: '4px',
    },
  },
  waitlistDone: {
    objectFit: 'contain',
    maxHeight: '150px',
    marginTop: '30px',
    [breakpoints.down('sm')]: {
      objectFit: 'contain',
      maxHeight: '140px',
    },
    [breakpoints.down('xs')]: {
      objectFit: 'contain',
      maxHeight: '140px',
    },
  },
  title: {
    marginTop: '10px',
    marginBottom: '10px',
    [breakpoints.down('sm')]: {
      fontSize: '1.7rem',
    },
    [breakpoints.down('xs')]: {
      fontSize: '.9rem',
      textAlign: 'center',
    },
  },
  text: {
    marginTop: '0px',
    marginBottom: '0px',
    [breakpoints.down('sm')]: {
      fontSize: '.9rem',
    },
    [breakpoints.down('xs')]: {
      fontSize: '.8rem',
      textAlign: 'center',
    },
  },
  waitNum: {
    backdropFilter: palette.primary.light,
    padding: '.3rem .8rem',
    margin: '4px 2px',
    borderRadius: '10px',
    backgroundImage: `-webkit-gradient(linear, left top, right bottom, from(#fff), to(${palette.primary.light}))`,
  },
}));

const WaitlistDone: React.FC = () => {
  const classes = useStyles();
  const currentPriority = useSelector<RootState>(
    (state) => state.waitlist.currentPriority,
  ) as number;

  return (
    <>
      <LazyLoadImage
        src="./assets/waitlist-done.png"
        alt="logo"
        className={classes.waitlistDone}
      />
      <DialogContent className={classes.dialogContent}>
        <h3 className={classes.title}>Thank you!</h3>
        <p className={classes.text}>
          You are number{' '}
          <span className={classes.waitNum}>
            {currentPriority && currentPriority}
          </span>{' '}
          on the list.
        </p>
        <p className={classes.text}>
          We will update you soon with your invite.
        </p>
      </DialogContent>
    </>
  );
};

export default WaitlistDone;
