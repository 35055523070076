import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from 'components/Routes';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
          fontFamily: 'Amiko ,sans-serif',
          fontSize: '16px',
          lineHeight: '1.5rem',

          [breakpoints.down('md')]: {
            fontSize: '14px',
            lineHeight: '1.25rem',
          },

          [breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '1rem',
          },
        },
        h1: {
          fontSize: '6rem',
          fontFamily: 'Montserrat,sans-serif',
          margin: 0,
          lineHeight: 1,
        },
        h2: {
          fontSize: '3.75rem',
          fontFamily: 'Montserrat,sans-serif',
          margin: 0,
          lineHeight: 1,
        },
        h3: {
          fontSize: '3rem',
          fontFamily: 'Montserrat,sans-serif',
          margin: 0,
          lineHeight: 1,
        },
        h4: {
          fontSize: '2.25rem;',
          fontFamily: 'Montserrat,sans-serif',
          margin: 0,
          lineHeight: '2.5rem',
        },
        h5: {
          fontSize: '1.875rem',
          fontFamily: 'Montserrat,sans-serif',
          margin: 0,
          lineHeight: '2.25rem',
        },
        h6: {
          fontSize: '1.5rem',
          fontFamily: 'Montserrat,sans-serif',
          margin: 0,
          lineHeight: '2rem',
        },
        p: {
          fontFamily: 'Amiko ,sans-serif',
          fontSize: '1.125rem',
          margin: 0,
        },
        li: {
          fontSize: '1rem',
          fontFamily: 'Amiko ,sans-serif',
        },
        a: {
          fontWeight: 'bold',
          textDecoration: 'none',
          color: '#38C6F4',
          textAlign: 'center',
          fontFamily: 'Amiko ,sans-serif',
          '&:hover': {
            color: '#FF9169',
            textDecoration: 'none',
          },
        },
        ul: {
          listStyleType: 'none',
        },
        span: {
          fontSize: '1rem',
          fontFamily: 'Amiko ,sans-serif',
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#FF9169',
      main: '#38C6F4',
      dark: '#1F5B92',
    },
    secondary: {
      main: '#FF9169',
      dark: '#868686',
    },
  },
});

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <Routes />
    </CssBaseline>
  </ThemeProvider>
);

export default App;
