import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import TitleContainer from './TitleContainer';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '100px',
    [breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '500px',
    marginBottom: '50px',
    [breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
    [breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },

  roadmapWrapper: {
    width: '100%',
    padding: '50px 0',
  },

  roadmap: {
    width: '60%',
    height: '100px',
    margin: '0 auto',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  roadmapText: {
    fontSize: '20px',
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}));

const Roadmap: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TitleContainer title="Roadmap" />
      <VerticalTimeline animate>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jan - Mar 2022"
          iconStyle={{ background: '#1F5B92', color: '#fff' }}
          // icon={<WorkIcon />}
        >
          <h5 className="vertical-timeline-element-subtitle">Q1</h5>
          <p>・Launch testnet</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Apr - Jun 2022"
          iconStyle={{ background: '#1F5B92', color: '#fff' }}
          // icon={<WorkIcon />}
        >
          <h5 className="vertical-timeline-element-subtitle">Q2</h5>
          <p>
            ・Retrospective on testnet launch <br />
            ・Incorporate feedback into subsequent testnets <br />
            ・Research on improving different aspects of Meson
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jul - Sep 2022"
          iconStyle={{ background: '#1F5B92', color: '#fff' }}
          // icon={<WorkIcon />}
        >
          <h5 className="vertical-timeline-element-subtitle">Q3</h5>
          <p>
            ・Closed alpha launch of wallet with initial users
            <br />
            ・Incorporate research prototypes from Q2 into Meson through
            subsequent testnets
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Oct - Dec 2022"
          iconStyle={{ background: '#1F5B92', color: '#fff' }}
          // icon={<WorkIcon />}
        >
          <h5 className="vertical-timeline-element-subtitle">Q4</h5>
          <p>
            ・Release papers and docs on the research done in Q3 for Meson
            <br />
            ・Launch mainnet of Meson sometime in 2023
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default Roadmap;
