import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from './TitleContainer';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '100px',
    [breakpoints.down('xs')]: {
      height: '100%',
    },
  },

  logos: {
    [breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  logo: {
    objectFit: 'contain',
    maxHeight: '100px',
    margin: '0 2rem',
    [breakpoints.down('sm')]: {
      maxHeight: '80px',
    },
    [breakpoints.down('xs')]: {
      maxHeight: '60px',
    },
  },
}));

const Sponsors: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TitleContainer title="Supported by" />

      <div className={classes.logos}>
        <img
          src="./assets/eth-foundation-logo.png"
          alt="ethFondation"
          className={classes.logo}
        />
        <img
          src="./assets/binance-fellowship-logo.png"
          alt="binance"
          className={classes.logo}
        />
      </div>
    </div>
  );
};

export default Sponsors;
