import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    [breakpoints.down('sm')]: {
      paddingRight: '.8rem',
      paddingLeft: '.8rem',
    },
  },
  contents: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  companyName: {
    margin: 0,
  },
  button: {
    backgroundColor: palette.primary.main,
    color: '#ffffff',
    margin: '0 5px',
    '&:hover': {
      background: palette.primary.dark,
      color: '#ffffff',
    },
    [breakpoints.down('sm')]: {
      fontSize: '.7rem',
      margin: '0 3px',
      padding: '.2rem .2rem .2rem .2rem',
    },
  },
  link: {
    color: 'black',
  },
}));

const Navbar: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <header className={classes.container}>
      <div className={classes.contents}>
        <Button
          variant="contained"
          justify-content="center"
          className={classes.button}
          component={Link}
          to="/"
        >
          Home
        </Button>
        <Button
          variant="contained"
          justify-content="center"
          className={classes.button}
          component={Link}
          to="/wallet"
        >
          Wallet
        </Button>
        <Button
          variant="contained"
          justify-content="center"
          className={classes.button}
          target="_blank"
          href="https://docs.mesonmix.net/"
        >
          Docs
        </Button>
      </div>
    </header>
  );
};

export default Navbar;
