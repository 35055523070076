import React, { VFC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { isErrorSlice } from 'features/error';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
    fontWeight: 'bold',
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const ErrorModal: VFC = () => {
  const classes = useStyles();
  // const isError = useSelector<RootState>((state) => state.isError.isError);
  const dispatch = useDispatch();
  const { isErrorGotten } = isErrorSlice.actions;

  const handleClose = () => {
    dispatch(isErrorGotten({ isError: false }));
  };

  return (
    <>
      <Modal
        open
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className={classes.error}
          >
            Oops, something was wrong...
          </Typography>
          <Typography id="modal-modal-description">
            Please sign up your email again.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ErrorModal;
