import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from './TitleContainer';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '100px',
    [breakpoints.down('xs')]: {
      height: '100%',
    },
  },

  card: {
    background: 'white',
    margin: '1rem',
    padding: '1.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    boxShadow: `2px 2px 2px${palette.secondary.dark}`,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  cards: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& div': {
        width: '100%',
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    objectFit: 'contain',
    width: '50px',
    marginRight: '.8rem',
    [breakpoints.down('sm')]: {
      width: '40px',
    },
    [breakpoints.down('xs')]: {
      width: '30px',
    },
  },
  socialMedia: {
    color: 'black',
    margin: '0',
  },
  description: {
    width: '400px',
    marginTop: '6px',
    marginBottom: '0',
    [breakpoints.down('xs')]: {
      width: '90%',
    },
  },
}));

const Community: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TitleContainer
        title="Join our community"
        description="Be part of our growing community of developers and innovators who are
        helping build the Meson network"
      />

      <div className={classes.card}>
        <a
          href="https://twitter.com/MesonMixnet"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classes.header}>
            <img
              src="./assets/twitter-logo.png"
              alt="twitter"
              className={classes.logo}
            />
            <h5 className={classes.socialMedia}>Twitter</h5>
          </div>
        </a>
        <p className={classes.description}>
          Follow{' '}
          <a
            href="https://twitter.com/MesonMixnet"
            target="_blank"
            rel="noopener noreferrer"
          >
            @MesonMixnet
          </a>{' '}
          to get the latest news and updates from across the ecosystem
        </p>
      </div>

      <div className={classes.cards}>
        <div className={classes.card}>
          <a
            href="https://app.element.io/#/room/#meson-community:matrix.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={classes.header}>
              <img
                src="./assets/element-logo.svg"
                alt="element"
                className={classes.logo}
              />
              <h5 className={classes.socialMedia}>Element</h5>
            </div>
          </a>
          <p className={classes.description}>
            Ask general questions and chat with the worldwide community on{' '}
            <a
              href="https://app.element.io/#/room/#meson-community:matrix.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Element
            </a>
          </p>
        </div>
        <div className={classes.card}>
          <a
            href="https://write.as/hashcloaks-blog/announcing-meson-an-ethereum-mixnet-to-rule-them-all"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={classes.header}>
              <img
                src="./assets/blog-logo.png"
                alt="blog"
                className={classes.logo}
              />
              <h5 className={classes.socialMedia}>Blog</h5>
            </div>
          </a>
          <p className={classes.description}>
            Read{' '}
            <a
              href="https://write.as/hashcloaks-blog/announcing-meson-an-ethereum-mixnet-to-rule-them-all"
              target="_blank"
              rel="noopener noreferrer"
            >
              our blog
            </a>{' '}
            to learn more about Meson
          </p>
        </div>
      </div>
    </div>
  );
};

export default Community;
