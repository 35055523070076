import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from './TitleContainer';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '100px',
    [breakpoints.down('xs')]: {
      height: '100%',
      marginBottom: '50px',
    },
  },

  contents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 2rem',
    [breakpoints.down('sm')]: {
      padding: '0 0',
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      marginBottom: '6px',
      paddingBottom: '6px',
      borderBottom: `3px solid${palette.primary.light}`,
    },
  },

  contentLast: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      marginBottom: '6px',
      paddingTop: '6px',
    },
  },

  divider: {
    width: '0',
    height: '80%',
    margin: '0 10px',
    borderLeft: `3px solid${palette.primary.light}`,
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const Features: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TitleContainer title="Features" />
      <div className={classes.contents}>
        <div className={classes.content}>
          <img src="./assets/features-open-source.png" alt="open-source" />
          <h5>Open-source</h5>
          <p>
            Meson is an open source project under the GPL license. We forked
            Katzenpost, a mix network funded through the EU&apos;s Horizon 2020
            program as we believe that continuing the work funded as part of
            public goods privacy project aligns with our goals of building
            multi-chain privacy public good.
          </p>
        </div>
        <span className={classes.divider} />
        <div className={classes.content}>
          <img src="./assets/features-multi-chain.png" alt="multi-chain" />
          <h5>Multi-chain</h5>
          <p>
            Meson is a multi-chain! That means you can send an anonymous
            transaction to any blockchain of your choosing. We currently support
            Ethereum, and major L2s such as Optimism, Arbitrum, Polygon. We will
            be adding support for more chains based on user demand
          </p>
        </div>
        <span className={classes.divider} />
        <div className={classes.contentLast}>
          <img src="./assets/features-easy-to-use.png" alt="easy-to-use" />
          <h5>Easy to use</h5>
          <p>
            Meson is easy to use for both regular users and developers! If you
            are a developer, just integrate our client into your wallet software
            and now your users have network-level privacy with Meson. If you are
            a user, you can use our wallet in order to enhance your privacy on
            chains like Ethereum.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;
