import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from './TitleContainer';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '100px',
    [breakpoints.down('xs')]: {
      height: '100%',
    },
  },

  contents: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 2rem',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '0 0',
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    maxWidth: '56rem',
    flex: 1,
  },

  contentBorder1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    maxWidth: '56rem',
    flex: 1,
    borderLeft: `3px solid ${palette.primary.light}`,
    [breakpoints.down('xs')]: {
      borderLeft: `0px solid ${palette.primary.light}`,
      borderTop: `3px solid ${palette.primary.light}`,
    },
  },
  contentBorder2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    maxWidth: '56rem',
    flex: 1,
    borderTop: `3px solid ${palette.primary.light}`,
  },

  icon: {
    width: '150px',
    objectFit: 'contain',
    [breakpoints.down('sm')]: {
      width: '100px',
    },
    [breakpoints.down('xs')]: {
      width: '80px',
    },
  },

  iconCeonsorship: {
    width: '150px',
    padding: '25px',
    objectFit: 'contain',
    [breakpoints.down('sm')]: {
      width: '100px',
      padding: '20px',
    },
  },
}));

const Advantages: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TitleContainer title="Why Meson?" />
      <div className={classes.contents}>
        <div className={classes.content}>
          <img
            src="./assets/advantages-censorship.png"
            alt="censorship-resistant"
            className={classes.iconCeonsorship}
          />
          <h5>Censorship Resistant</h5>
          <p>
            Meson can&apos;t block your transaction from being propagated to the
            blockchain of your choice!
          </p>
        </div>
        <div className={classes.contentBorder1}>
          <img
            src="./assets/advantages-metadata.png"
            alt="metadata-resistant"
            className={classes.icon}
          />
          <h5>Metadata-resistant</h5>
          <p>
            As a mix network, one of Meson&apos;s key features is that it is
            metadata-resistant, no information about your IP and access patterns
            are leaked.
          </p>
        </div>
      </div>

      <div className={classes.contents}>
        <div className={classes.contentBorder2}>
          <img
            src="./assets/advantages-custom-tailored.png"
            alt="custom-tailored"
            className={classes.icon}
          />
          <h5>Custom-tailored for blockchains</h5>
          <p>
            Meson is built specifically to cater to the needs of
            privacy-conscious blockchain users.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
