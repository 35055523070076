import React, { VFC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

type ContainerProps = {
  title: string;
  description?: string;
};

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    [breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
    [breakpoints.down('xs')]: {
      marginBottom: '30px',
    },
  },

  title: {
    color: palette.primary.light,
    margin: '0 15px',
    [breakpoints.down('sm')]: {
      fontSize: '34px',
    },
    [breakpoints.down('xs')]: {
      fontSize: '24px',
      textAlign: 'center',
    },
  },

  textLine: {
    width: '80px',
    height: '3px',
    background: palette.primary.light,
  },

  subText: {
    width: '40%',
    textAlign: 'center',
    marginBottom: '30px',
    [breakpoints.down('sm')]: {
      width: '60%',
    },
    [breakpoints.down('xs')]: {
      width: '80%',
    },
  },
}));

const TitleContainer: VFC<ContainerProps> = ({ title, description }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleWrapper}>
        <span className={classes.textLine} />
        <h3 className={classes.title}>{title.toUpperCase()}</h3>
        <span className={classes.textLine} />
      </div>
      {description && description ? (
        <p className={classes.subText}>{description}</p>
      ) : null}
    </>
  );
};

export default TitleContainer;
