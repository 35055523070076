import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { signUpContent, signUpSubject } from 'domains/services/email-content';
import postEmail from 'domains/services/post-email';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [breakpoints.down('xs')]: {},
  },

  textLine: {
    background: palette.primary.dark,
    width: '10%',
    height: '3px',
  },

  text: {
    color: palette.primary.dark,
    width: '70%',
    marginTop: '15px',
    marginBottom: '10px',
    [breakpoints.down('sm')]: {
      marginTop: '5px',
      width: '80%',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center',
    width: '80%',
    marginTop: '16px',

    [breakpoints.down('xs')]: {
      width: '90%',
    },

    '& div > h5': {
      color: palette.primary.dark,
      margin: '0 10px',
    },

    '& form': {
      display: 'flex',
      alignItems: 'center',
      marginTop: '0.5rem',
      [breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },

    '& div > label': {
      [breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },

  textField: {
    width: '70%',
    '& .MuiFilledInput-root': {
      background: 'white',
    },
  },
  button: {
    backgroundColor: palette.primary.light,
    color: '#ffffff',
    marginLeft: '10px',

    '&:hover': {
      background: palette.primary.dark,
    },
  },
  error: {
    color: 'red',
    fontWeight: 'bold',
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const SignUpForm: React.FC = () => {
  const classes = useStyles();
  const formType = 'sign-up';
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const history = useHistory();

  useEffect(() => {
    let isUnmounted = false;

    const load = async (): Promise<void> => {
      if (!isUnmounted && isSubmitted) {
        const sentEmail = await postEmail(
          email,
          signUpSubject,
          signUpContent,
          formType,
        );
        if (sentEmail) {
          history.push('/success');
        } else {
          setOpen(true);
          setIsSubmitted(false);
          setChecked(!checked);
        }
      }
      setIsLoading(false);
    };

    void load();

    return () => {
      isUnmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isSubmitted]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleAccept = () => {
    setChecked(!checked);
    setIsLoading(!isLoading);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setIsLoading(!isLoading);
    setIsSubmitted(true);
  };

  return (
    <div className={classes.container}>
      <div className={classes.form}>
        <div className={classes.titleWrapper}>
          <span className={classes.textLine} />
          <h5>Sign up for updates</h5>
          <span className={classes.textLine} />
        </div>
        <form
          name={formType}
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <TextField
            variant="outlined"
            required
            type="email"
            name="email"
            id="email"
            placeholder="email"
            color="primary"
            className={classes.textField}
            label="email"
            size="small"
            onChange={handleChange}
          />
          <br />
          <Button
            variant="contained"
            justify-content="center"
            type="submit"
            className={classes.button}
            disabled={!isLoading}
          >
            Sign up
          </Button>
        </form>
        <FormGroup>
          <FormControlLabel
            label={
              <div>
                <span>I accept the </span>
                <Link to="/privacypolicy">privacy policy</Link>
              </div>
            }
            control={<Checkbox checked={checked} />}
            onChange={handleAccept}
          />
        </FormGroup>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className={classes.error}
          >
            Oops, something was wrong...
          </Typography>
          <Typography id="modal-modal-description">
            Please sign up your email again.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default SignUpForm;
