import React from 'react';
import { makeStyles } from '@material-ui/core';
import MainVisual from 'components/modules/MainVisual';
import Features from 'components/modules/Features';
import Advantages from 'components/modules/Advantages';
import Roadmap from 'components/modules/Roadmap';
import Community from 'components/modules/Community';
import Sponsors from 'components/modules/Sponsors';
import Works from 'components/modules/Works';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '0',
    width: '100%',
    hight: '100%',
  },
}));

const MainContents: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <MainVisual />
        <Works />
        <Features />
        <Advantages />
        <Roadmap />
        <Community />
        <Sponsors />
      </div>
    </>
  );
};
export default MainContents;
