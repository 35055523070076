import React from 'react';
import { makeStyles } from '@material-ui/core';
import Navbar from 'components/modules/Navbar';
import Thanks from '../subpages/Thanks';
import Footer from '../modules/Footer';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0',
    padding: '0',
    minHeight: '100vh',
  },
}));

const SuccessPage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Navbar />
      <Thanks />
      <Footer />
    </div>
  );
};
export default SuccessPage;
