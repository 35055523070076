import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      padding: '0, .25rem',
    },
  },
  title: {
    color: palette.primary.light,
    textAlign: 'center',
  },
  text: {
    color: palette.secondary.dark,
    textAlign: 'center',
    fontWeight: 'normal',
  },

  list: {
    borderTop: `solid 5px ${palette.primary.light}`,
    boxShadow: `0 1px 2px ${palette.secondary.dark}`,
    borderRadius: '0 0 .25rem .25rem',
    padding: '1rem',
    margin: '1rem',
  },

  listItem: {
    listStyleType: 'none',
    marginBottom: '.375rem',
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    textAlign: 'left',
    fontWeight: 'normal',
  },

  logo: {
    object: 'fit',
    width: '30px',
    marginRight: '6px',
    [breakpoints.down('sm')]: {
      width: '24px',
    },
  },

  mesonLogo: {
    objectFit: 'contain',
    maxHeight: '11rem',
    marginBottom: '-.625rem',
    [breakpoints.down('sm')]: {
      maxHeight: '9rem',
    },
    [breakpoints.down('xs')]: {
      maxHeight: '8rem',
    },
  },
}));

const SignUpForm: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LazyLoadImage
        src="./assets/meson-logo-sm.png"
        alt="logo"
        className={classes.mesonLogo}
      />

      <h5 className={classes.title}>
        Thank you for signing up <br />
        for learning about our upcoming testnet!
      </h5>

      <h6 className={classes.text}>
        We will reach out to you soon about participating in our upcoming
        testnet!
      </h6>

      <ul className={classes.list}>
        <p>In the meantime, you can</p>
        <li className={classes.listItem}>
          <a
            href="https://write.as/hashcloaks-blog/announcing-meson-an-ethereum-mixnet-to-rule-them-all"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <img
              src="./assets/blog-logo.png"
              alt="blog-logo"
              className={classes.logo}
            />
            Read this post to learn more about Meson
          </a>
        </li>
        <li className={classes.listItem}>
          <a
            href="https://twitter.com/MesonMixnet"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <img
              src="./assets/twitter-logo.png"
              alt="twitter-logo"
              className={classes.logo}
            />
            Follow us on Twitter for updates about Meson
          </a>
        </li>
        <li className={classes.listItem}>
          <a
            href="https://app.element.io/#/room/#meson-community:matrix.org"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <img
              src="./assets/element-logo.svg"
              alt="element-logo"
              className={classes.logo}
            />
            Join our element community to keep in touch with the team and get
            involved
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SignUpForm;
