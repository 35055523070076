import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import TitleContainer from './TitleContainer';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '100px',
    [breakpoints.down('xs')]: {
      height: '100%',
      marginBottom: '50px',
    },
  },

  contents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '1rem',
  },

  images: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },

  contentTitle: {
    marginTop: '0',
    marginBottom: '-20px',
    [breakpoints.down('sm')]: {
      fontSize: '24px',
      marginBottom: '-10px',
    },
    [breakpoints.down('xs')]: {
      fontSize: '20px',
      marginBottom: '-5px',
    },
  },

  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& img': {
      maxWidth: '100px',
      objectFit: 'contain',
    },

    [breakpoints.down('xs')]: {
      maxWidth: '100%',
      height: '100%',
    },
  },

  gridItemArrow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '200px',
    [breakpoints.down('xs')]: {
      height: '100px',
    },
  },
  arrow: {
    width: '100%',
    maxWidth: '100px',
    objectFit: 'contain',
    marginTop: '30px',
    padding: '0 0.5rem',
    [breakpoints.down('xs')]: {
      transform: 'rotate(90deg)',
      margin: '10px',
      height: '100%',
    },
  },
}));

const HowWorks: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TitleContainer title="How Meson Works" />
      <div className={classes.contents}>
        <Grid container justify="center">
          <Grid item className={classes.gridItem} sm={3} xs={12}>
            <img
              src="./assets/how-meson-works-transactions.png"
              alt="transactions"
            />
            <h5>Transactions</h5>
            <p>
              Users use a Meson-enabled wallet that will handle connecting to
              the Meson network
            </p>
          </Grid>
          <Grid item className={classes.gridItemArrow} sm={1} xs={12}>
            <img
              src="./assets/how-meson-works-arrow.png"
              alt="arrow"
              className={classes.arrow}
            />
          </Grid>
          <Grid item className={classes.gridItem} sm={3} xs={12}>
            <img src="./assets/how-meson-works-mixnet.png" alt="mixnet" />
            <h5>Mixnet</h5>
            <p>
              Once their transactions are ready, these transactions will be
              bundled up to be sent through the Meson network in order to cover
              the originator of the transaction
            </p>
          </Grid>
          <Grid item className={classes.gridItemArrow} sm={1} xs={12}>
            <img
              src="./assets/how-meson-works-arrow.png"
              alt="arrow"
              className={classes.arrow}
            />
          </Grid>
          <Grid item className={classes.gridItem} sm={3} xs={12}>
            <img
              src="./assets/how-meson-works-blockchain.png"
              alt="blockchain"
            />
            <h5>Blockchain</h5>
            <p>
              Once the transaction has propagated through the Meson network, it
              is then delivered to the transaction&apos;s blockchain
              destination. External observers of these blockchains won&apos;t be
              able to see who sent the transaction, just that the transaction
              came from the Meson network
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HowWorks;
