import React from 'react';
import ThanksMessage from 'components/modules/ThanksMessage';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    justifyContent: 'center',
    flex: '1',
    [breakpoints.down('sm')]: {},

    '& > div': {
      marginRight: '0',
    },
    '& > div > h4': {
      maxWidth: 'initial',
    },
  },
}));

const ComingSoon: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ThanksMessage />
    </div>
  );
};

export default ComingSoon;
