/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, DialogContent, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { postWaitlist } from 'domains/services/post-waitlist';
import { waitlistSlice } from 'features/waitlist';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features/reducers';
import ErrorModal from 'components/modules/ErrorModal';
import { isErrorSlice } from 'features/error';
import {
  waitlistContent,
  waitlistSubject,
} from 'domains/services/email-content';
import postEmail from 'domains/services/post-email';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '0',
    paddingBottom: '10px',
    margin: '0 0.125rem',
  },
  mesonLogo: {
    objectFit: 'contain',
    maxHeight: '150px',
    [breakpoints.down('sm')]: {
      objectFit: 'contain',
      maxHeight: '180px',
    },
    [breakpoints.down('xs')]: {
      objectFit: 'contain',
      maxHeight: '140px',
    },
  },
  title: {
    marginBottom: '10px',
    textAlign: 'center',
    color: palette.primary.light,
  },
  subTitle: {
    marginBottom: '4px',
    maxWidth: '90%',
    textAlign: 'center',
    color: palette.primary.main,

    [breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  text: {
    marginTop: '4px',
    marginBottom: '0px',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  textField: {
    width: '60%',
    marginRight: '10px',
    '& .MuiFilledInput-root': {
      background: 'transparent',
    },
    [breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '4px',
    },
  },
  button: {
    backgroundColor: palette.primary.light,
    color: '#ffffff',
    '&:hover': {
      background: palette.primary.dark,
    },
    [breakpoints.down('sm')]: {
      fontSize: '.7rem',
    },
  },
}));

const WaitlistJoin: React.FC = () => {
  const classes = useStyles();
  const formType = 'waitlist';
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isError = useSelector<RootState>((state) => state.isError.isError);
  const { waitlistGotten } = waitlistSlice.actions;
  const { isErrorGotten } = isErrorSlice.actions;

  useEffect(() => {
    let isUnmounted = false;
    const url = window.location.href;

    const load = async (): Promise<void> => {
      if (!isUnmounted && isSubmitted) {
        try {
          const waitlist = await postWaitlist(email, url);

          if (waitlist && waitlist.status === 200) {
            const { current_priority, total_waiters_currently } = waitlist.data;
            dispatch(
              waitlistGotten({
                currentPriority: current_priority,
                totalWaitersCurrently: total_waiters_currently,
                joinedWaitlist: true,
              }),
            );

            const handleEmail = await postEmail(
              email,
              waitlistSubject,
              waitlistContent,
              formType,
            );

            if (!handleEmail) {
              dispatch(isErrorGotten({ isError: true }));
            }
          }
        } catch (err) {
          dispatch(isErrorGotten({ isError: true }));
        }
        setIsLoading(false);
        setIsSubmitted(false);
      }
    };
    void load();

    return () => {
      isUnmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);
  };

  return (
    <>
      <LazyLoadImage
        src="./assets/meson-logo-sm.png"
        alt="logo"
        className={classes.mesonLogo}
      />
      <DialogContent className={classes.dialogContent}>
        <h5 className={classes.title}>
          A privacy-focused wallet for managing your crypto assets
        </h5>
        <h6 className={classes.subTitle}>
          Meson wallet is an open source, non-custodial, privacy-focused
          Ethereum wallet for desktop.
        </h6>
        <p className={classes.text}>
          Sign up for our waitlist for access when we are ready.
        </p>
        <form
          name={formType}
          className={classes.form}
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <TextField
            variant="outlined"
            required
            type="email"
            name="email"
            id="email"
            placeholder="email"
            color="secondary"
            label="email"
            size="small"
            className={classes.textField}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            justify-content="center"
            type="submit"
            className={classes.button}
            disabled={isLoading}
          >
            Join waitlist
          </Button>
        </form>
        {isError ? <ErrorModal /> : null}
      </DialogContent>
    </>
  );
};

export default WaitlistJoin;
