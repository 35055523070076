import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { gsap, Power3 } from 'gsap';
import SignUpForm from 'components/modules/SignUpForm';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100vh',
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      minHeight: '0',
    },
  },
  wrapperLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  title: {
    color: palette.primary.main,
    fontWeight: 'bold',
  },
  subtext: {
    maxWidth: '600px',
    marginBottom: '10px',
    fontStyle: 'italic',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  wrapperRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    objectFit: 'contain',
    maxHeight: '300px',
    [breakpoints.down('sm')]: {
      objectFit: 'contain',
      maxHeight: '200px',
    },
    [breakpoints.down('xs')]: {
      objectFit: 'contain',
      maxHeight: '150px',
    },
  },
  text: {
    fontWeight: 'normal',
    marginTop: '-30px',
    color: palette.secondary.dark,
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'space-between',
  },
  
  button: {
    backgroundColor: palette.primary.main,
    color: '#ffffff',
    margin: '0 5px',
    '&:hover': {
      background: palette.primary.dark,
      color: '#ffffff',
    },
    [breakpoints.down('xs')]: {
      fontSize: '.7rem',
      margin: '0 3px',
      padding: '.2rem .2rem .2rem .2rem',
    },
  },
}));

const MainVisual: React.FC = () => {
  const classes = useStyles();
  const logoItem = useRef<HTMLImageElement>(null);

  const setAnimation = () => {
    gsap.fromTo(
      logoItem.current,
      2,
      {
        opacity: 0,
        y: 15,
        ease: Power3.easeOut,
        delay: 0.1,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power3.easeInOut,
        delay: 0.1,
      },
    );
  };

  useEffect(() => {
    setAnimation();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.wrapperLeft}>
        <h1 className={classes.title}>Meson</h1>
        <h6 className={classes.subtext}>
          Meson is a mix network for cryptocurrency transactions.
          It breaks the link connecting your network-level information to your
          cryptocurrency transactions!
        </h6>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            justify-content="center"
            className={classes.button}
            component={Link}
            to="/wallet"
          >
            Wallet
          </Button>
          <Button
            variant="contained"
            justify-content="center"
            className={classes.button}
            target="_blank"
            href="https://docs.mesonmix.net/"
          >
            Docs
          </Button>
        </div>
        <SignUpForm />
      </div>
      <div className={classes.wrapperRight} ref={logoItem}>
        <img
          src="./assets/meson-logo-sm.png"
          alt="logo"
          className={classes.logo}
        />
        <h4 className={classes.text}>A multi-chain mixnet</h4>
      </div>
    </div>
  );
};

export default MainVisual;
